<template>
  <VacationRequest
      :id="id"
      @close="onClose"
  />
</template>

<script>
import VacationRequest from '../../components/UserVacationRequests/VacationRequest';

export default {
  name: 'VacationRequestShow',
  components: {VacationRequest},
  props: ['id'],
  methods: {
    onClose() {
      this.$router.push({name: 'VacationRequestsList'});
    },
  },
};
</script>

<style scoped>

</style>
